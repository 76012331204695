// store.js
import React, { createContext } from "react"
import sessionMachineFactory from "src/machines/session"
import { useMachine } from "@xstate/react"

const initialState = {}
const store = createContext(initialState)
const { Provider } = store

const XStateProvider = ({ children }) => {
  console.log(window.localStorage.getItem("user"))
  const storage = {
    token: window.localStorage.getItem("token")
      ? window.localStorage.getItem("token")
      : null,
    user:
      window.localStorage.getItem("user") !== "undefined"
        ? JSON.parse(window.localStorage.getItem("user"))
        : null,
    loginURL: window.localStorage.getItem("loginURL")
      ? window.localStorage.getItem("loginURL")
      : null,
  }
  console.log(storage, "STORE")
  const { loginURL, token, user } = storage
  const [state, send] = useMachine(sessionMachineFactory(loginURL, token, user))
  return <Provider value={{ state, send }}>{children}</Provider>
}

export { store, XStateProvider }
